/*
 * @Author: wangqs 
 * @description 实名认证
 * @Date: 2021-07-11 16:24:29 
 * @Last Modified by: wangqs
 * @Last Modified time: 2022-04-16 08:37:17
 */
<template>
  <div class="page-box">
    <div class="title-text">
      身份证正面信息
    </div>
    <div
      class="box info-box"
    >
      <van-field
        v-model="info.userName"
        required
        label="姓名"
        placeholder=" "
        input-align="right"
        :disabled="true"
      />
      <van-field
        v-model="info.idCardNo"
        required
        label="身份证号码"
        placeholder=" "
        input-align="right"
        :disabled="true"
      />
    </div>

    <div class="title-text">
      身份证反面信息
    </div>
    <div
      class="box info-box"
    >
      <van-field
        v-model="info.issue"
        required
        label="签发机构"
        placeholder=" "
        input-align="right"
        :disabled="true"
      />
      <van-field
        v-model="expiryDateStr"
        required
        label="有效期限"
        placeholder=" "
        input-align="right"
        :disabled="true"
      />
    </div>

    <div
      class="card-box"
      style="margin-top: 20px;"
    >
      <img
        v-if="info.frontUrl"
        class="idcrad-img"
        :src="info.frontUrl"
      >
      <div
        v-else
        class="box"
      >
        <img
          class="id-img"
          :src="require('@/assets/img/idcard-front-bg.png')"
          alt=""
        >
      </div>
      <div class="scan-img-box">
        <img
          class="scan-img"
          :src="require('@/assets/img/scan-bg.png')"
          alt=""
        >
        <van-uploader
          ref="uploadBox"
          v-model="frontUrl"
          :after-read="(query)=>{afterRead(query, 'frontUrl')}"
          :max-size="20 * 1024 * 1024"
          :preview-image="false"
          @oversize="onOversize"
          @delete="(e)=>{deleteItem(e, 'frontUrl')}"
        >
        </van-uploader>
      </div>
      <div class="card-text">
        身份证正面
      </div>
    </div>
    <div class="card-box">
      <img
        v-if="info.backUrl"
        class="idcrad-img"
        :src="info.backUrl"
      >
      <div
        v-else
        class="box"
      >
        <img
          class="id-img"
          :src="require('@/assets/img/idcard-back-bg.png')"
          alt=""
        >
      </div>
      <div class="scan-img-box">
        <img
          class="scan-img"
          :src="require('@/assets/img/scan-bg.png')"
          alt=""
        >
        <van-uploader
          v-model="backUrl"
          :after-read="(query)=>{afterRead(query, 'backUrl')}"
          :max-size="20 * 1024 * 1024"
          :preview-image="false"
          @oversize="onOversize"
          @delete="(e)=>{deleteItem(e, 'backUrl')}"
        >
        </van-uploader>
      </div>
      <div class="card-text">
        身份证反面
      </div>
    </div>

    <!-- 提交 -->
    <van-button
      class="submit-btn"
      color="#51B464"
      round
      type="info"
      @click="nextBtn"
    >
      下一步
    </van-button>
  </div>
</template>

<script>
export default {
  name: 'Test',
  components: {
  },
  data () {
    return {
      isGlobalPage: false,
      backUrl: [],
      frontUrl: [],
      info: {
        backUrl: '',
        frontUrl: ''
      }
    }
  },
  computed: {
    expiryDateStr () {
      return (this.info.issueDate || '') + '-' + (this.info.expiryDate || '')
    }
  },
  created () {
    let info = JSON.parse(window.localStorage.getItem('userCheckInfo') || '{}')
    // info.expiryDateStr = (info.issueDate || '') + '-' + (info.expiryDate || '')
    this.info = info
  },
  methods: {
    nextBtn () {
      if (!this.info.frontUrl) {
        this.$toast('请上传身份证正面')
      } else if (!this.info.backUrl) {
        this.$toast('请上传身份证反面')
      } else {
        // this.$router.push('/order/addBank?id=' + this.$route.query.id)
        window.localStorage.setItem('userCheckInfo', JSON.stringify(this.info))
        window.localStorage.setItem('orderInfo', JSON.stringify(this.info))
        this.$router.push('/school/authInfo?id=' + this.$route.query.id)
      }
    },
    afterRead (file, key) {
      console.log(file)
      let vm = this;
      var fd = new FormData()
      // fd.append('Content-Type', 'multipart/form-data')
      fd.append('multipartFile', file.file)
      fd.append('dir', 'org')
      vm.$toast.loading({
        message: '加载中',
        forbidClick: true,
        duration: 0
      });
      var xhr = new XMLHttpRequest()
      xhr.open('POST', '/gateway/img/upload', true)
      xhr.onload = function () {
        if (Math.floor(xhr.status / 100) === 2) {
          var res = JSON.parse(xhr.response)
          if (res.code === 200) {
            console.log(res, '上传成功')
            // file.url = res.msg.url || ''
            key ? vm[key] == [] : ''
            vm.sendUrl(key, res.msg.url || '')
          } else {
            vm.$toast.clear()
            vm.$toast('上传失败，请检查后重新上传')
          }
        } else {
          // clearInterval(vm.timerClock)
          vm.$toast.clear()
          vm.$toast('上传失败')
        }
        // vm.$toast.clear()
      }
      xhr.onerror = function () {
        vm.$toast.clear()
        vm.$toast.fail('文件 ' + file.name + ' 上传失败')
      }
      xhr.setRequestHeader('token', JSON.parse(window.localStorage.getItem('userData') || '{}').token)
      xhr.send(fd)
    },
    onOversize (file) {
      console.log(file);
      this.$toast('文件大小不能超过 2M');
    },
    deleteItem (e, key) {
      console.log(e, key)
    },
    async sendUrl (key, url) {
      if (url) {
        let data = {
          type: key === 'backUrl' ? 2 : 1,
          url
        }
        this.$toast.loading({
          message: '加载中',
          forbidClick: true,
          duration: 0
        });
        let res = await this.$http.orderApi.orderOrc(data)
        this.$toast.clear()
        console.log(res, 'res')
        if (res.code === 200) {
          this.sendImgInfo = res.msg
          if (res.msg) {
            this.$set(this.info, key, url || '')
            console.log(this.info, 'info')
            if (key === 'backUrl') {
              this.info.expiryDate = res.msg.expiryDate || ''
              this.info.issueDate = res.msg.issueDate || ''
              this.info.issue = res.msg.issue || ''
            } else {
              this.info.idCardNo = res.msg.idCardNo || ''
              this.info.userName = res.msg.userName || ''
            }
          }
        }
      } else {
        this.$toast('上传失败')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  .title-text {
    padding-left: 16px;
    background: #f5f5f5;
    line-height: 32px;
    font-size: 15px;
    font-weight: bold;
  }
  .info-box {
    padding: 0 10px;
  }
  .card-box {
    width: 238px;
    height: 150px;
    margin: 0 auto 10px;
    border: 1px dashed #ccc;
    background: #F9F9F9;
    position: relative;
    border-radius: 4px;
    .idcrad-img {
      width: 100%;
      height: 100%;
    }
    .id-img {
      width: 156px;
      height: 102px;
      margin: 15px auto 0;
      display: block;
    }
    .scan-img-box {
      position: absolute;
      width: 78px;
      height: 78px;
      left: 50%;
      margin-left: -39px;
      top: 27px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      .scan-img {
        width: 32px;
        height: 32px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -16px 0 0 -16px;
      }
    }
    .card-text {
      width:100%;
      text-align: center;
      font-size: 13px;
      color: #999;
      line-height:30px;
      position: absolute;
      bottom: 8px;
    }
  }
  .submit-btn {
    width: 345px;
    margin: 20px auto 0;
    display: block;
  }
  /deep/ .van-uploader {
    opacity: 0;
    background: rgba(0, 0, 0, 0)
  }
}
</style>
